// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-about-us-js": () => import("./../../../src/pages/aboutUs.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-admin-account-statement-js": () => import("./../../../src/pages/admin/accountStatement.js" /* webpackChunkName: "component---src-pages-admin-account-statement-js" */),
  "component---src-pages-admin-clients-js": () => import("./../../../src/pages/admin/clients.js" /* webpackChunkName: "component---src-pages-admin-clients-js" */),
  "component---src-pages-admin-facebook-js": () => import("./../../../src/pages/admin/facebook.js" /* webpackChunkName: "component---src-pages-admin-facebook-js" */),
  "component---src-pages-admin-login-js": () => import("./../../../src/pages/admin/login.js" /* webpackChunkName: "component---src-pages-admin-login-js" */),
  "component---src-pages-client-account-statement-js": () => import("./../../../src/pages/client/accountStatement.js" /* webpackChunkName: "component---src-pages-client-account-statement-js" */),
  "component---src-pages-home-js": () => import("./../../../src/pages/home.js" /* webpackChunkName: "component---src-pages-home-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-instagram-js": () => import("./../../../src/pages/instagram.js" /* webpackChunkName: "component---src-pages-instagram-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-plan-calculator-js": () => import("./../../../src/pages/planCalculator.js" /* webpackChunkName: "component---src-pages-plan-calculator-js" */)
}

